<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <SectionContent style="background-color: #fff">
                <com-list-search>
                    <el-form :inline="true" size="medium" slot="list-search-before">
                        <el-form-item>
                            <ApiSelect placeholder="请选择发布类型" :options="trainingTypeOptions" clearable
                                       v-model="form.trainingType"/>
                        </el-form-item>
                        <el-form-item>
                            <el-input placeholder="请输入主题" style="width: 240px" clearable
                                      v-model="form.trainingName"/>
                        </el-form-item>
                    </el-form>
                    <div slot="list-search-after">
                        <el-button @click="resetClick()">重置</el-button>
                        <el-button type="primary" @click="getList()">搜索</el-button>
                        <el-button type="success" @click="handleAdd">发布宣导</el-button>
                    </div>
                </com-list-search>
                <el-table style="width: 100%;" border v-loading="loading" :data="result.data">
                    <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
                    <el-table-column prop="trainingName" label="宣导主题" width="150" align="center"></el-table-column>
                    <el-table-column prop="trainingType" label="发布类型" width="120" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.trainingType | formatDesc(trainingTypeOptions) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="发布状态" align="center" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.trainingStatus === 1 ? '已发布' : '待发布' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="发布时间" width="150" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.createTime | dateFormat('YYYY-MM-DD HH:mm') }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="lastTime" label="最晚完成时间" width="150" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.lastTime">
                            {{ scope.row.lastTime | dateFormat('YYYY-MM-DD HH:mm') }}
                            </span>
                            <span v-else>无截止时间</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pushType" label="发布范围">
                        <template slot-scope="scope">
                            <p v-if="scope.row.cityName">按城市：<br/>{{ scope.row.cityName }}</p>
                            <p v-if="scope.row.operatorName">按运营商：<br/>{{ scope.row.operatorName }}</p>
                            <p v-if="scope.row.supplierName">按供应商：<br/>{{ scope.row.supplierName }}</p>
                            <p v-if="!scope.row.cityName&&!scope.row.operatorName&&!scope.row.supplierName">全部</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="关联考题" align="center" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.userQuestionDtos ? scope.row.userQuestionDtos.length : 0 }}
                        </template>
                    </el-table-column>
                    <el-table-column label="完成率" align="center" width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     :href="'#/completeDetails?id='+scope.row.id+'&name='+scope.row.trainingName"
                                     v-if="scope.row.driverCount">
                                {{ parseInt(scope.row.completeCount / scope.row.driverCount * 100) }}%
                            </el-link>
                            <span v-else>
                --
              </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120" align="center">
                        <template slot-scope="scope">
                            <el-link type="primary" :underline="false" size="mini" @click="handleDetail(1,scope.row)">
                                查看
                            </el-link>
                            <el-link type="primary" :underline="false" size="mini" class="ml10"
                                     v-if="scope.row.trainingStatus !== 1||scope.row.trainingType===3"
                                     @click="handleDetail(undefined,scope.row)">编辑
                            </el-link>
                            <el-popconfirm v-if="scope.row.trainingStatus === 1&&scope.row.trainingType!==3"
                                           title="确定撤回？"
                                           @confirm="handleChangeState({id:scope.row.id,trainingStatus: 0})">
                                <el-link slot="reference" size="mini" class="ml10" type="danger" :underline="false">
                                    撤回
                                </el-link>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </SectionContent>
        </el-main>
        <el-footer style="display: flex;justify-content: flex-end;align-items: center">
            <el-pagination background layout="prev, pager, next"
                           :current-page="form.pageIndex"
                           :total="result.total"
                           @current-change="getList">
            </el-pagination>
        </el-footer>
    </div>
</template>

<script>
import {trainingTypeOptions} from '@/data/driverTraining'
import comListSearch from "@/www/components/comListSearch/index.vue";
import SectionContent from "@/components/sectionContent/index.vue";
import dayjs from "dayjs";
import {trainingListApi, updateTrainingStatusApi} from "@/www/urls/driverLearning";
import {combinationForm} from "@/common/js/common";


const createForm = () => ({
    pageIndex: 1,
    pageSize: 10,
    messageType: undefined,
    messageTitle: undefined
})
export default {
    name: "driverLearning",
    data() {
        return {
            trainingTypeOptions,
            loading: false,
            form: createForm(),
            result: {
                data: [],
                total: 0
            }
        }
    },
    filters: {
        filterState(val, record) {
            if (record.isDelete === 1)
                return '已删除'
            if (val === 1)
                return '已取消'
            return !record.pushTime || (dayjs(record.pushTime).isBefore(dayjs())) ? '已发布' : '待发布'
        },
        filterStateType(val, record) {
            if (val === 1)
                return 'info'
            return !record.pushTime || (dayjs(record.pushTime).isBefore(dayjs())) ? 'success' : 'warning'
        }
    },
    components: {
        comListSearch,
        SectionContent
    },
    activated() {
        if (this.$route.query.t !== this._t) {
            this._t = this.$route.query.t;
            this.getList();
        }
    },
    methods: {
        async getList(page = 1) {
            this.form.pageIndex = page;
            this.loading = true
            const ret = await trainingListApi(combinationForm(this.form))
            this.loading = false
            if (ret.success)
                this.result = ret.data;
            else this.$message.error(ret.errors[0].message)

        },
        handleDetail(type, record) {
            this.$router.push({
                path: type === 1 ? '/driverLearningDetail' : '/driverLearningEdit',
                query: {
                    form: JSON.stringify(record),
                    type,
                    t: +new Date()
                }
            })
        },
        resetClick() {
            this.form = createForm();
            this.getList(1)
        },
        handleAdd() {
            this.$router.push({
                path: '/driverLearningAdd',
                query: {
                    t: +new Date()
                }
            })
        },
        async handleChangeState(form) {
            this.loading = true;
            const ret = await updateTrainingStatusApi(form)
            if (!ret.success) {
                this.loading = false;
                return this.$message.error(ret.errors[0].message)
            }
            this.getList();
        }
    }
}
</script>

<style scoped lang="scss">
.eTrip-section-cont {
    margin-top: 10px;

    &::v-deep {
        .list-search-after {
            width: 275px;
        }
    }
}

.message-info-btn {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
</style>
